import React from 'react';
import "../../App.css";

import AboutDownloadPanel from "../../components/about/AboutDownloadPanel";
import AboutDownloadConfirmation from "../../components/about/AboutDownloadConfirmation";

class About extends React.Component {
  
  state = {
    
    showConfirm: false,
    currentFileLabel: "",
    currentFileLink: "",
    emailTemplateId: "template_2kdwJuYZ",
    submitted: false,
    downloads: [
      {
        url: "https://drive.google.com/file/d/1EeSrIsLT373xn0E8jJ7T97KWWN1J2cO8/view?usp=sharing",
        label: "Corporate Profile",
        extraClass: "about-download-profile"
      },
      {
        url: "https://drive.google.com/file/d/1_bW2k36TpAXJbpVI9uz4Kgf-G6wXJ2Hv/view?usp=sharing",
        label: "Project Images",
        extraClass: "about-download-images"
      },
      {
        url: "https://drive.google.com/file/d/1HrEVeQyo9YwoisLWNuZVhKLWB-owbujV/view?usp=sharing",
        label: "Project List",
        extraClass: "about-download-list"
      }
    ],
  }
  
  renderDownloadPanels = () => {
    
    const panelList = this.state.downloads.slice();
    var i = 0;
    
    return (
      <React.Fragment>
        {panelList.map((data) => (
          <AboutDownloadPanel 
            key={i}
            index={i++}
            url={data.url}
            label={data.label}
            onDownloadClicked={this.onDownloadClicked}
          />
        ))}
      </React.Fragment>
    );
  }

  onDownloadClicked = (currentFileLabel, currentFileLink) => {
    
    console.log("onDownloadClicked");
    
    const { stateCopy } = this.state;
    
    this.setState({
      ...stateCopy,
      showConfirm: true,
      submitted: false,
      currentFileLabel: currentFileLabel,
      currentFileLink: currentFileLink
    });
  }

  onHideConfirm = () => {
    
    console.log("onHideConfirm");
    
    const { stateCopy } = this.state;
    
    this.setState({
      ...stateCopy,
      showConfirm: false
    });
  }
  
  onSubmitted = () => {
    
    const { stateCopy } = this.state;
    
    this.setState({
      ...stateCopy,
      submitted: true
    });
  }
  
  render()
  {
    
    const lineOne = (<React.Fragment><div className="company-name">Bluerail Tech Inc.</div>Specializes in the field of project management, engineering and construction of Civil, Structural  & Electro-Mechanical Systems.</React.Fragment>
    );
    
    const lineTwo = (
      <React.Fragment>We use the latest software technologies for custom-designed applications.This enables us to provide our customers pro-active, fast, and accurate designs and estimates critical to successful project planning and implementation.<br/><br/>Part, assembly, and general arrangement drawings are generated automatically including bill of materials, bolt list, and drawing list.  The bill of materials report shows all pertinent data such as weights, surface areas, and dimensions of each member.  Any revisions are cascaded to all drawings and reports instantaneously making revision tracking easier and error-free.</React.Fragment>
    )
    
    const lineFour = (
      <React.Fragment>Structural and mechanical designs are presented both in 2D and 3D using Tekla Structures and CAD software to enable our customers to realistically visualize the project even before it is constructed.</React.Fragment>
    )
    
    const lineFive = (
      <React.Fragment>Our expertise in engineering and architectural software utilization combined with our extensive experience in project management is further enhanced by our technical collaboration with carefully selected companies specialized in several aspects of fabrication: <br/><br/>
      -  precision machining & fabrication <br/>
      -  mechanical fabrication <br/>
      -  structural fabrication <br/>
      -  painting & surface preparation</React.Fragment>
    )
    
    return (
      <div className="content-container">
        <AboutDownloadConfirmation 
          shown={this.state.showConfirm} 
          onHideConfirm={this.onHideConfirm}
          onSubmitted={this.onSubmitted}
          emailTemplateId={this.state.emailTemplateId}
          submitted={this.state.submitted}
          downloadLabel={this.state.currentFileLabel}
          downloadLink={this.state.currentFileLink}
        />
        <div className="about-info-section"> 
          <div className="about-info-one">
            {lineOne}
          </div>
          <div className="about-large-img">
      
          </div>
          <div className="about-info-two"> 
            {lineTwo}
          </div>
          <div className="about-gallery">
            <div className="about-gallery-img about-gallery-one">
      
            </div>
            <div className="about-gallery-img about-gallery-two">
      
            </div>
            <div className="about-gallery-img about-gallery-three">
              
            </div>
          </div>
          <div className="about-info-three">
            <div className="about-small-info about-left">
              {lineFour}
            </div>
            <div className="about-small-info about-right">
              {lineFive}
            </div>
          </div>
        </div>
        <div className={"content-section about-downloads-section"}>
          <p className="about-downloads-header">
            Download our...
          </p>
          <div className="about-downloads-grid">
            {this.renderDownloadPanels()}
          </div>
        </div>
      </div>
    ); 
  }
}

export default About;