import React from 'react';
import { BrowserRouter, Link } from 'react-router-dom';
import "../../App.css";

class HeaderButton extends React.Component {
  
  render()
  {
    return (
      <Link to={this.props.link} className='nav-button'>
        {this.props.label}
      </Link>
    ); 
  }
}

export default HeaderButton;