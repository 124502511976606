import React from 'react';
import "../../App.css";

import SampleImg from '../../images/sample.jpg';

class ServicePanel extends React.Component {
  
  renderServiceLines = () => {
    
    return (
      <React.Fragment>
        {this.props.lines.map((data) => (
          <p className="serviceLine">- {data}</p>
         ))}
      </React.Fragment>
    )
  }
  
  render()
  {
    const imgClass = this.props.classImgName;
    
    const reverse = this.props.itemNo % 2 == 0;
    
    const serviceImgClass = 
      reverse ? 
      "service-img-left" : 
      "service-img-right";
    
    const orderClass = 
      reverse ? 
      "service-panel-reverse" :
      "service-panel-normal";
    
    return (
      <div className={"service-panel " + orderClass}> 
        <div className="service-info-panel">
          <div className="service-header">
            {this.props.itemNo}. {this.props.header}
          </div>
          <div className="service-divider"></div>
          <div className="service-lines">
            {this.renderServiceLines()}
          </div>
        </div>
        <div className={"service-img" + " " + serviceImgClass + " " + imgClass}>
          
        </div>
      </div>
    ); 
  }
}

const serviceImageBaseStyle = {
  
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  backgroundRepeat: 'none',
}

export default ServicePanel;