import React from 'react';
import { BrowserRouter, Link } from 'react-router-dom';
import "../../App.css";

class Splash extends React.Component {
  
  render()
  {
    return (
      <div style={splashContainerStyle}>
        <div className="splash-image-container">
          <div className="splash-overlay">
            <div className="splash-overlay-content"> 
              <p className="splash-slogan">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </p>
              <Link
                to="/about"
                className="splash-button"
                >
                About Us
              </Link>
            </div>
          </div>
        </div>
      </div>
    ); 
  }
}

const splashContainerStyle = {
  
  height: '100vh',
  width: '100vw',
  
  background: 'rgba(0, 0, 64, 0.25)',
}

export default Splash;