import React from 'react';
import "../../App.css";

import Directions from "../../images/directions.jpg";

import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';
import ContactPerson from "../../components/contact/ContactPerson";

import RamonPortrait from "../../images/ramon.jpg";
import IanPortrait from "../../images/ian.jpg";
import HenryPortrait from "../../images/henry.jpg";

class Contact extends React.Component {
  
  state = {
    
    contacts: [
      {
        portraitUrl: RamonPortrait,
        name: "Ramon Richard R. Luisking",
        position: "Chairman / President",
        email: "ramon.luisking@bluerailtech.com",
      },
      {
        portraitUrl: IanPortrait,
        name: "Ian A. Burce",
        position: "Managing Director, Engineering",
        email: "ian.burce@bluerailtech.com",
      },
      {
        portraitUrl: HenryPortrait,
        name: "Henry Albert P. Orcine",
        position: "Managing Director, Projects",
        email: "henry.orcine@bluerailtech.com",
      }
    ],
    userFirstName: "",
    validFirstName: true,
    userLastName: "",
    validLastName: true,
    userEmail: "",
    validEmail: true,
    userNumber: "",
    validNumber: true,
    userMessageContent: "",
    validMessage: true,
    submitted: false
  }

  handleFirstNameChange = (event) => {
    
    var stateCopy = this.state;
    stateCopy.userFirstName = event.target.value;
    
    this.setState({
      ...stateCopy
    })
  }

  handleLastNameChange = (event) => {
    
    var stateCopy = this.state;
    stateCopy.userLastName = event.target.value;
    
    this.setState({
      ...stateCopy
    })
  }

  handleEmailChange = (event) => {
    
    var stateCopy = this.state;
    stateCopy.userEmail = event.target.value;
    
    this.setState({
      ...stateCopy
    })
  }
  
  handleNumberChange = (event) => {
    
    var stateCopy = this.state;
    stateCopy.userNumber = event.target.value;
    
    this.setState({
      ...stateCopy
    })
  }
  
  handleMessageContentChange = (event) => {
    
    var stateCopy = this.state;
    stateCopy.userMessageContent = event.target.value;
    
    this.setState({
      ...stateCopy
    })
  }
  
  validateFirstName = () => {
    
    var isValid = true;
    
    if (this.state.userFirstName === "") {
      isValid = false;
    }
    
    const { stateCopy } = this.state;
    
    this.setState({
      ...stateCopy,
      validFirstName: isValid
    })
    
    return isValid;
  }
  
  validateLastName = () => {
    
    var isValid = true;
    
    if (this.state.userLastName === "") {
      isValid = false;
    }
    
    const { stateCopy } = this.state;
    
    this.setState({
      ...stateCopy,
      validLastName: isValid
    })
    
    return isValid;
  }
  
  validateMessage = () => {
    
    var isValid = true;
    
    if (this.state.userMessageContent === "") {
      isValid = false;
    }
    
    const { stateCopy } = this.state;
    
    this.setState({
      ...stateCopy,
      validMessage: isValid
    })
    
    return isValid;
  }
  
  validateEmail = () => {
    
    var isValid = true;
    
    var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
    
    if (!pattern.test(this.state.userEmail)) {
      isValid = false;
    }
    
    const { stateCopy } = this.state;
    
    this.setState({
      ...stateCopy,
      validEmail: isValid
    })
    
    return isValid;
  }
  
  validateNumber = () => {
    
    var isValid = true;
    
    if (this.state.userNumber === "") {
      isValid = false;
    }
    
    const { stateCopy } = this.state;
    
    this.setState({
      ...stateCopy,
      validNumber: isValid
    })
    
    return isValid;
  }
  
  onSubmitPressed = () => {
    
    if(this.state.submitted)
      return;
    
    const userEmail = this.state.userEmail;
    const userMessage = this.state.userMessageContent;
    const userFirstName = this.state.userFirstName;
    const userLastName = this.state.userLastName;
    const label = this.props.label;
    const link = this.props.url;

		const templateId = "template_t298zeq";

    const validFirstName = this.validateFirstName();
    const validLastName = this.validateLastName();
    const validEmail = this.validateEmail();
    const validNumber = this.validateNumber();
    const validMessage = this.validateMessage();

		const subjectContent = "Message From " + userFirstName;

    if(validFirstName && validLastName && validEmail && validNumber && validMessage)
    {
      this.sendEmail(
        templateId,
				{
					subject: subjectContent,
					to_email: "ramon.luisking@bluerailtech.com",
          from_email: userEmail,
          message: userMessage,
          first_name: userFirstName,
          last_name: userLastName
        }
      )
			
      this.resetFields();
      
      var stateCopy = this.state;
      stateCopy.submitted = true;

      this.setState({
        ...stateCopy
      })
    }
  }
  
  resetFields = () => {
    
    var stateCopy = this.state;
    stateCopy.validEmail = true;
    stateCopy.validNumber = true;
    stateCopy.validFirstName = true;
    stateCopy.validLastName = true;
    stateCopy.validMessage = true;
    
    this.setState({
      ...stateCopy
    })
  }
  
  sendEmail = (templateId, variables) => {
    
    window.emailjs.send(
    'gmail', templateId, variables
    ).then(res => {
      console.log("Email sent!")
    }).catch(err => {
      console.error("Email not sent! " + err)
    });
  }
  
  renderContacts = () => {
    
    return (
      <React.Fragment>
        {this.state.contacts.map((data) => (
           <ContactPerson 
            name={data.name}
            position={data.position}
            number={data.number}
            altNumber={data.altNumber}
            email={data.email}
            portraitUrl={data.portraitUrl}
            />
        ))}
      </React.Fragment>
    )
  }
  
  render()
  {
    var buttonClass = 
        this.state.submitted ?
        "submit-message-button-submitted" :
        "submit-message-button-unsubmitted";
    
    var firstNameErrorClass = 
        this.state.validFirstName ? 
        "about-download-confirm-error-hidden" :
        "about-download-confirm-error-shown";
    
    var lastNameErrorClass = 
        this.state.validLastName ? 
        "about-download-confirm-error-hidden" :
        "about-download-confirm-error-shown";
    
    var emailErrorClass = 
        this.state.validEmail ? 
        "about-download-confirm-error-hidden" :
        "about-download-confirm-error-shown";
    
    var numberErrorClass = 
        this.state.validNumber ? 
        "about-download-confirm-error-hidden" :
        "about-download-confirm-error-shown";
    
    var messageErrorClass = 
        this.state.validMessage ? 
        "about-download-confirm-error-hidden" :
        "about-download-confirm-error-shown";
    
    return (
      <div className="content-container">
        <div className="section-header">Contact Us</div>
        <div className="contact-person-section">
          {this.renderContacts()}
        </div>
        <div className="address-section">
          <p className="map-info-header">Office Address</p>
          <p className="map-address"> 
            Bluerail Tech Building, Block 1 Lot 2 <br/>
            Cream St., SSS Village, Concepcion Dos, Marikina City <br/>
            Philippines 1811 <br/>
            Tel Nos. (+632) 730-3472 & (+632) 624-5015 <br/>
            Email :  info@bluerailtech.com
          </p>
          <a
            href="https://www.google.com/maps/place/Bluerail+Tech+Inc/@14.6367632,121.1048655,15.78z/data=!4m5!3m4!1s0x3397b9b24c5f458f:0x9826c2241d74a0fe!8m2!3d14.6363988!4d121.1110722"
            target="_blank"
            className="map-container">
          </a>
        </div>
        <div className="submit-message-section">
          <div className="section-header">Send Us An Email</div>
          <form className="submit-message-form"> 
            
            <div className="submit-message-names-panel">
              
              <div className="submit-message-info-panel">
                
                <label 
                  className="submit-message-label"
                  htmlFor="user-first-name-submit">
                    First Name:
                </label>
                <input 
                  onChange={this.handleFirstNameChange}
                  className="submit-message-textfield"
                  type="text"
                  id="user-first-name-submit" 
                  name="user-first-name-submit"
                />
                <p className={ "submit-message-error " + firstNameErrorClass } >
                  Please enter your first name
                </p>
                    
              </div>
              
              <div className="submit-message-info-panel">
                
                <label 
                  className="submit-message-label"
                  htmlFor="user-last-name-submit">
                    Last Name:
                </label>
                <input 
                  onChange={this.handleLastNameChange}
                  className="submit-message-textfield"
                  type="text"
                  id="user-last-name-submit" 
                  name="user-last-name-submit"
                />
                <p className={ "submit-message-error " + lastNameErrorClass } >
                  Please enter your last name
                </p>

              </div>
              
            </div>

            <div className="submit-message-contact-info-panel">
              
              <div className="submit-message-info-panel">
                
                <label 
                  className="submit-message-label"
                  htmlFor="user-email-submit">
                    Email:
                </label>
                <input 
                  onChange={this.handleEmailChange}
                  className="submit-message-textfield"
                  type="text" 
                  id="user-email-submit" 
                  name="user-email-submit"
                />
                <p className={ "submit-message-error " + emailErrorClass } >
                  Please enter a valid email address
                </p>

              </div>
              
              <div className="submit-message-info-panel">
                
                <label 
                  className="submit-message-label"
                  htmlFor="user-number-submit">
                    Mobile Number:
                </label>
                <input 
                  onChange={this.handleNumberChange}
                  className="submit-message-textfield"
                  type="number" 
                  id="user-number-submit" 
                  name="user-number-submit"
                />
                <p className={ "submit-message-error " + numberErrorClass } >
                  Please enter a valid number
                </p>

              </div>

            </div>

            <div className="submit-message-content-panel">
              
              <label 
                className="submit-message-label"
                htmlFor="user-message-content-submit">
                  Message:
              </label>

              <textarea 
                onChange={this.handleMessageContentChange}
                className="submit-message-textfield"
                type="text" 
                id="user-message-content-submit" 
                name="user-message-content-submit"
                rows="8"
              >
              </textarea>
              <p className={ "submit-message-error " + messageErrorClass } >
                Please enter a valid message
              </p>

            </div>

            <div
              onClick={this.onSubmitPressed.bind(this)}
              className={"submit-message-button " + buttonClass} 
            >{this.state.submitted ? "Submitted" : "Submit"}</div>
          </form>
        </div>
      </div>
    ); 
  }
}

/*

Map API

<Map 
  google={this.props.google}
  zoom={20}
  className="map"
  initialCenter={{ lat: 14.636430, lng: 121.111061 }}
>
  <Marker position={{ lat: 14.636430, lng: 121.111061 }} />
</Map>

export default GoogleApiWrapper({
  apiKey: 'API KEY HERE'
})(Contact)

*/

const mapStyle = {
  
  width: "600px",
  height: "400px",
}

export default Contact;