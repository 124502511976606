import React from 'react';
import "../../App.css";

import ServicePanel from "../../components/services/ServicePanel";

class Services extends React.Component {
  
  state = {
    
    services: [
      {
        header: "Structural Steelworks",
        classImgName: "service-structural-img",
        lines: [
          "Design", 
          "Detailing", 
          "Fabrication", 
          "Surface Preparation",
          "Painting", 
          "Erection"
        ]
      },
      {
        header: "Electro-mechanical Works",
        classImgName: "service-electro-img", 
        lines: [
          "Design", 
          "Detailing", 
          "Fabrication", 
          "Surface Preparation", 
          "Painting", 
          "Erection", 
          "Testing", 
          "Commissioning"
        ]
      },
      {
        header: "Civil & Architectural Works",
        classImgName: "service-civil-img",
        lines: [
          "Design", 
          "Detailing", 
          "Construction"
        ]
      },
      {
        header: "Industrial Equipment Supply",
        classImgName: "service-industrial-img",
        lines: [
          "LED Energy Efficient Lighting",
          "Pulse-jet Bag Filter Dust Collectors",
          "Filter Bags (Regular, Anti-static, Water-resistant, NOMEX, ETC.)",
          "Abrasion-resistant & Anti-hang Liners",
          "Belt Converyor Accessories",
          "Rotary Airlock, Screw Converyor, & Shaft Seals"
        ]
      },
    ]
  }
  
  renderServicePanels = (serviceList) => {
    
    var i = 1;
    
    return (
      <React.Fragment>
        {this.state.services.map((data) => (
         <ServicePanel 
            key={i}
            itemNo={i++}
            header={data.header}
            lines={data.lines}
            classImgName={data.classImgName}
          />
         ))}
      </React.Fragment>
    )
  }
  
  render()
  {
    return (
      <div className="content-container">
        <div className="services-section">
          <div className="section-header">Our services</div>
          {this.renderServicePanels()}
        </div>
      </div>
    ); 
  }
}

export default Services;