import React from 'react';
import { BrowserRouter, Link } from 'react-router-dom';
import "../../App.css";

class AboutDownloadConfirmation extends React.Component {
  
  state = {

    userEmail: "",
    validEmail: true,
    userNumber: "",
    validNumber: true,
    submitted: false
  }

  onSubmitPressed = () => {
    
    if(!this.props.submitted)
    {
      const userEmail = this.state.userEmail;
      const label = this.props.downloadLabel;
      const link = this.props.downloadLink;
     
			const templateId = "template_2kdwJuYZ";
			const templateIdInform = "template_t298zeq";

      const validEmail = this.validateEmail();
      const validNumber = this.validateNumber();

			const informMessage = userEmail + " has downloaded " + label + ".";

      console.log(label);
      console.log(link);
      
      if(validEmail)
      {
        console.log("VALID! Sending email to " + userEmail);
      } else
      {
        console.log("INVALID! Not sending email to " + userEmail);
      }
      
      if(validEmail && validNumber)
      {
        this.sendEmail(
          templateId,
          {
            to_email: userEmail,
            download_label: label,
            download_link: link
          }
        )

				this.sendEmail(
					templateIdInform,
					{
						subject: "Bluerail Tech Inc. Download Notification",
						to_email: "info@bluerailtech.com",
						from_email: "ramon.luisking14@gmail.com",
						message: informMessage,
						first_name: "Tup",
						last_name: "Luisking"
					}
				)

        this.props.onSubmitted.call();  
        this.resetFields();
      }
    }
  }
  
  hidePanel = () => {
    
    this.props.onHideConfirm.call(this);
    this.resetFields();
  }
  
  resetFields = () => {
    
    var stateCopy = this.state;
    stateCopy.validEmail = true;
    stateCopy.validNumber = true;
    
    this.setState({
      ...stateCopy
    })
  }
  
  handleEmailChange = (event) => {
    
    var stateCopy = this.state;
    stateCopy.userEmail = event.target.value;
    
    this.setState({
      ...stateCopy
    })
  }
  
  handleNumberChange = (event) => {
    
    var stateCopy = this.state;
    stateCopy.userNumber = event.target.value;
    
    this.setState({
      ...stateCopy
    })
  }
  
  validateEmail = () => {
    
    var isValid = true;
    
    var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
    
    if (!pattern.test(this.state.userEmail)) {
      isValid = false;
    }
    
    const { stateCopy } = this.state;
    
    this.setState({
      ...stateCopy,
      validEmail: isValid
    })
    
    return isValid;
  }
  
  validateNumber = () => {
    
    var isValid = true;
    
    if (this.state.userNumber === "") {
      isValid = false;
    }
    
    const { stateCopy } = this.state;
    
    this.setState({
      ...stateCopy,
      validNumber: isValid
    })
    
    return isValid;
  }
  
  sendEmail = (templateId, variables) => {
    
    window.emailjs.send(
    'gmail', templateId, variables
    ).then(res => {
      console.log("Email sent!")
    }).catch(err => {
      console.error("Email not sent! " + err)
    });
  }
  
  render()
  {
    var shownClass = 
        this.props.shown ? 
        "about-download-shown" :
        "about-download-hidden";
    
    var buttonClass = 
        this.props.submitted ?
        "about-download-confirm-submitted" :
        "about-download-confirm-unsbumitted";
    
    var emailErrorClass = 
        this.state.validEmail ? 
        "about-download-confirm-error-hidden" :
        "about-download-confirm-error-shown";
    
    var numberErrorClass = 
        this.state.validNumber ? 
        "about-download-confirm-error-hidden" :
        "about-download-confirm-error-shown";
    
    return (
      <div className={ 'about-download-confirm-panel ' + shownClass }>
        <div 
          className="about-download-overlay"
          onClick={this.hidePanel.bind(this)}
        ></div>
        <form className="about-download-confirm-form"> 
          <div 
            className="about-download-confirm-close"
            onClick={this.hidePanel.bind(this)}
            >X</div>
          <div className="about-download-confirm-header">
            Downloading<br/>
            {this.props.currentFileLabel}
          </div>
          <label 
            className="about-download-confirm-label"
            htmlFor="user-email">
              Email:
          </label>
          <input 
            onChange={this.handleEmailChange}
            className="about-download-confirm-textfield"
            type="text" 
            id="user-email" 
            name="user-email"
          />
          <p className={ "about-download-confirm-error " + emailErrorClass } >
            Please enter a valid email address
          </p>
          <label 
            className="about-download-confirm-label"
            htmlFor="user-number">
              Contact Number:
          </label>
          <input 
            onChange={this.handleNumberChange}
            className="about-download-confirm-textfield"
            type="number" 
            id="user-number" 
            name="user-number"
          />
          <p className={ "about-download-confirm-error " + numberErrorClass } >
            Please enter a valid number
          </p>
          <div
            onClick={this.onSubmitPressed.bind(this)}
            className={"about-download-confirm-button " + buttonClass} 
          >{this.props.submitted ? "Sent To Email" : "Submit"}</div>
        </form>
      </div>
    ); 
  }
}

export default AboutDownloadConfirmation;