import React from 'react';
import '../../App.css';

class Footer extends React.Component {
  
  render()
  {
    return (
      <div className="footer-main">
        <div className="footer-left">
          <p>Bluerail Tech Building,</p>
          <p>Block 1 Lot 2 Cream St.,</p>
          <p>SSS Village,</p>
          <p>Concepcion Dos</p>
          <p>Marikina City,</p>
          <p>Metro Manila</p>
          <p>1811 Manila</p>
          <br/>
          <p>ph: +6327303472</p>
          <p>alt: +63276245015</p>
          <p className="footer-link">info@bluerailtech.com</p>
        </div>
        <div className="footer-right">
          <p>Copyright 2018 Bluerail Tech Inc.</p>
          <p>All rights reserved.</p>
        </div>
      </div>
    ); 
  }
}

export default Footer;