import React from 'react';
import "../../App.css";

class ContactPerson extends React.Component {
  
  render()
  {
    const emailHref = "mailto:" + this.props.email;
    
    return (
      <div className="contact-person">
        <img src={this.props.portraitUrl} className="contact-portrait"/>
        <div className="contact-info"> 
          <span style={nameStyle}>{this.props.name}</span>
          {this.props.position} <br/><br/>
          <a href={emailHref}>{this.props.email}</a>
        </div>
      </div>
    ); 
  }
}

const nameStyle = {
  
  fontSize: '1.25em',
  fontWeight: 'bold'
}

export default ContactPerson;