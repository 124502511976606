import React from 'react';
import "../../App.css";

import { BrowserRouter, Link } from "react-router-dom";

import Logo from "../../images/bluerail_logo.jpg";

import HeaderButton from "../../components/header/HeaderButton";

class Header extends React.Component {
  
  createButton = (label, link) => {
    
    return (
      <HeaderButton 
        label = {label}
        link = {link}
      />
    )
  }
  
  render()
  {
    return (
      <div className="header-main">
        <Link to="/" className="header-logo-container">
          <img className="header-logo" src={Logo} />
        </Link>
        <div className="nav-buttons">
          {this.createButton("About Us", "/about")}
          {this.createButton("Services", "/services")}
          {this.createButton("Contact Us", "/contact")}
        </div>
      </div>
    ); 
  }
}

export default Header;