import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { AnimatedSwitch } from 'react-router-transition';

import './App.css';

import Header from './components/header/Header';
import Footer from './components/footer/Footer';

import Splash from './components/splash/Splash';
import About from './components/about/About';
import Services from './components/services/Services';
import Contact from './components/contact/Contact';

function App() {
  return (
    <Router>
    
      <Route exact path="/"> 

        <Header />
        <About />
        <Footer />

      </Route>

      <Route path="/home"> 

        <Header />
        <About />
        <Footer />
    
      </Route>

      <Route path="/about">

        <Header />
        <About />
        <Footer />

      </Route>
    
      <Route path="/services">

        <Header />
        <Services />
        <Footer />

      </Route>
    
      <Route path="/contact">

        <Header />
        <Contact />
        <Footer />

      </Route>
    
    </Router>
  );
}

const contentContainerStyle = {
  
  paddingTop: '75px',
  minHeight: '1000px',
}

export default App;
