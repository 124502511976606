import React from 'react';
import "../../App.css";

import Profile from "../../images/profile.png";
import Images from "../../images/images.png";
import List from "../../images/list.png";

class AboutDownloadPanel extends React.Component {
  
  images = [
    Profile,
    Images,
    List
  ]
  
  downloadFile = () => {
    
    console.log("downloading " + this.props.url);  
    
    this.props.onDownloadClicked.call(
      this, 
      this.props.label,
      this.props.url
    );
  }
  
  render()
  {
    return (
      <div 
      onClick={this.downloadFile.bind(this)} 
      className="about-download-panel"
      >
        <img src={this.images[this.props.index]} className={"about-download-preview " + this.props.extraClass}/>
        <p className="about-download-name">{this.props.label}</p>
      </div>
    ); 
  }
}

export default AboutDownloadPanel;